@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Poppins;
    font-weight: 400;
    src: url(./fonts/Poppins-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: Poppins;
    font-weight: 700;
    src: url(./fonts/Poppins-Bold.ttf) format("truetype");
  }
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(136, 84, 243);
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
